import { voiceoverEN } from '../data/voices/voiceover';
import { voices } from '../data/voices/voices';
import { getCredits, updateCredits } from '../helpers/credits.services';
import {
  buildVideoSection,
  getErrorMessages,
  getVideosBySections,
  getVoiceOverMessages,
  getVoiceOvers,
  updateVideosWithError,
  validateFulfilled
} from '../helpers/helper';
import Toast from '~/helpers/notification';

export const useVoiceOverStore = (set, get) => ({
  // state
  voiceOver: { label: 'Eric', value: 'tony', language: 'EN', style: 'Default', disabled: false },
  voiceOptions: [...voiceoverEN],
  voiceOverPreview: { voice: '', style: '', action: '' },
  voiceOverPreviewAudio: null,
  voiceoverMessage: '',

  // actions
  setVoiceOverAction: async (voiceOver, voiceOverStyle, idx) => {
    const setVoiceOver = () => {
      if (voiceOver.value !== get().voiceOver.value || voiceOverStyle !== get().voiceOver.style) {
        set({
          isLoading: true,
          changeRunning: 'voiceover',
          voiceoverMessage: getVoiceOverMessages(),
          errorMessage: getErrorMessages()
        });

        let _sections = [...get().sections];

        let _voiceOver = {
          label: voiceOver.label,
          value: voiceOver.value,
          language: voiceOver.language,
          style: voiceOverStyle === null ? voiceOver.style : voiceOverStyle,
          disabled: voiceOverStyle === null ? !voiceOver.disabled : false
        };
        set({ voiceOver: _voiceOver }, false, 'Set voice over init');

        if (_voiceOver.disabled) {
          _sections.map((section, sectionIdx) => {
            if (section.errors?.length === 0) {
              let _currentSection = section;
              const _medias = section.medias;
              const _voiceoverAudio = {
                disabled: get().voiceOver.disabled,
                audios: section.voiceoverAudio.audios
              };
              const _music = section.music;
              let _voiceover = section.voiceover;

              const _section = buildVideoSection(
                _currentSection,
                _voiceover,
                _medias,
                _voiceoverAudio,
                _music,
                get().videoProportion,
                get().preset.value,
                get().textStyles
              );

              _sections[sectionIdx] = _section;

              set(
                { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
                false,
                'Update section - voice0ver'
              );

              // !- Update videos in history
              if (_section.videos[0]?.isCreated) {
                get().updateVideosHistoryAction(get().user._id, _section);
              }
            }
          });

          set({ isLoading: false, changeRunning: 'none' });
        } else {
          if (get().credits > 0) {
            // !- INFO: validate credits
            (async () => {
              try {
                _sections.map((section, sectionIdx) => {
                  if (section.errors?.length === 0) {
                    _sections[sectionIdx].isLoading = true;
                    set(
                      {
                        sections: [..._sections],
                        videosBySections: getVideosBySections(_sections)
                      },
                      false,
                      'Loading section - voiceover'
                    );
                  }
                });

                const creditsRemaining = await getCredits(get().user._id, get().plan);

                const videos = getVideosBySections(get().sections);
                const creditsCost = videos.length * get().editionCosts;

                if (creditsCost <= creditsRemaining) {
                  Promise.allSettled(
                    _sections.map((section, sectionIdx) => {
                      return new Promise((resolve, reject) => {
                        if (section.errors?.length === 0) {
                          (async () => {
                            try {
                              const _voiceOvers = await getVoiceOvers(
                                section.voiceover,
                                get().voiceOver,
                                voices,
                                get().preset.value
                              );
                              const fulfilled = validateFulfilled(_voiceOvers);
                              if (fulfilled) {
                                let voiceOvers = [];
                                _voiceOvers.forEach((m) => {
                                  voiceOvers.push(m.value);
                                });

                                let _currentSection = section;
                                const _medias = section.medias;
                                const _voiceoverAudio = {
                                  disabled: get().voiceOver.disabled,
                                  audios: voiceOvers
                                };
                                const _music = section.music;
                                let _voiceover = section.voiceover;

                                const _section = buildVideoSection(
                                  _currentSection,
                                  _voiceover,
                                  _medias,
                                  _voiceoverAudio,
                                  _music,
                                  get().videoProportion,
                                  get().preset.value,
                                  get().textStyles
                                );

                                (async () => {
                                  // !- INFO: discount the credits
                                  const data = {
                                    userId: get().user._id,
                                    usage: _section.videos.length * get().editionCosts,
                                    plan: get().plan,
                                    action: 'idea-to-video-change-voice-over'
                                  };
                                  const creditsRemaining = await updateCredits(data);

                                  if (creditsRemaining >= 0) {
                                    _sections[sectionIdx] = _section;
                                    set(
                                      {
                                        sections: [..._sections],
                                        videosBySections: getVideosBySections(_sections),
                                        credits: creditsRemaining
                                      },
                                      false,
                                      'Update section - voice0ver'
                                    );

                                    // !- Update videos in history
                                    if (_section.videos[0]?.isCreated) {
                                      await get().updateVideosHistoryAction(
                                        get().user._id,
                                        _section
                                      );
                                    }

                                    resolve(voiceOvers);
                                  } else {
                                    _sections[sectionIdx].errors.push({
                                      error: 'Error discounting credits'
                                    });
                                    _sections[sectionIdx].videos = updateVideosWithError(
                                      [..._sections[sectionIdx].videos],
                                      get().videoProportion
                                    );
                                    _sections[sectionIdx].isLoading = false;
                                    set(
                                      {
                                        sections: [..._sections],
                                        videosBySections: getVideosBySections(_sections)
                                      },
                                      false,
                                      'Error discounting credits'
                                    );

                                    reject('Error discounting credits');
                                  }
                                })();
                              } else {
                                _sections[sectionIdx].errors.push({ error: 'An error' });
                                _sections[sectionIdx].videos = updateVideosWithError(
                                  [..._sections[sectionIdx].videos],
                                  get().videoProportion
                                );
                                _sections[sectionIdx].isLoading = false;
                                set(
                                  {
                                    sections: [..._sections],
                                    videosBySections: getVideosBySections(_sections)
                                  },
                                  false,
                                  'Error in voice over'
                                );

                                reject('An error');
                              }
                            } catch (err) {
                              console.log('ERROR!_', err);
                              _sections[sectionIdx].errors.push({ error: err });
                              _sections[sectionIdx].videos = updateVideosWithError(
                                [..._sections[sectionIdx].videos],
                                get().videoProportion
                              );
                              _sections[sectionIdx].isLoading = false;
                              set(
                                {
                                  sections: [..._sections],
                                  videosBySections: getVideosBySections(_sections)
                                },
                                false,
                                'Error in section'
                              );

                              reject(err);
                            }
                          })();
                        } else {
                          resolve();
                        }
                      });
                    })
                  ).then(() => {
                    set({ isLoading: false, changeRunning: 'none' });

                    // toast for credits
                    let _credits = 0;
                    get().videosBySections.forEach((v) => {
                      if (!v.video.error) _credits = _credits + get().editionCosts;
                    });
                    if (_credits > 0) {
                      Toast.success(
                        `Discount applied! ${_credits} credits deducted for video editing.`
                      );
                    }
                  });
                } else {
                  get().setShowCreditsAction(true);

                  _sections.map((section, sectionIdx) => {
                    if (section.errors?.length === 0) {
                      _sections[sectionIdx].isLoading = false;
                      set(
                        {
                          sections: [..._sections],
                          videosBySections: getVideosBySections(_sections)
                        },
                        false,
                        'Loading section - voiceover'
                      );
                    }
                  });

                  set({ isLoading: false, changeRunning: 'none' });
                }
              } catch (error) {
                console.error('ERROR - CREDITS', error);

                _sections.map((section, sectionIdx) => {
                  if (section.errors?.length === 0) {
                    _sections[sectionIdx].isLoading = false;
                    set(
                      {
                        sections: [..._sections],
                        videosBySections: getVideosBySections(_sections)
                      },
                      false,
                      'Loading section - voiceover'
                    );
                  }
                });

                throw new Error('Error in getting the credits');
              }
            })();
          } else {
            get().setShowCreditsAction(true);
            set({ isLoading: false });
          }
        }

        set({ isOpenCustomize: false });
      }
    };

    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      // ! INFO: (idx !== 0) -> Premium
      if (idx !== 0) {
        if (!get().plan) {
          set({
            premiumModal: { type: 'voiceover', show: true }
          });
        } else {
          setVoiceOver();
        }

        get().trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'premium-voice-over',
          label: `${voiceOver.label}-${voiceOverStyle}`
        });
      } else {
        setVoiceOver();
      }
    }
  },

  setVoiceOverPreviewAction: (voice, style) => {
    const voicePreviewBaseUrl =
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676049171/Woxo/Idea2video/voiceover_previews/';

    const _url = `${voicePreviewBaseUrl}${voice}-${style}.mp3`;
    let _voiceOverPreview = { voice: voice, style: style, action: get().voiceOverPreview.action };

    if (voice === get().voiceOverPreview.voice && style === get().voiceOverPreview.style) {
      if (get().voiceOverPreview.action === 'pause') {
        get().voiceOverPreviewAudio.play();
        _voiceOverPreview.action = 'play';
      } else {
        get().voiceOverPreviewAudio.pause();
        _voiceOverPreview.action = 'pause';
      }
    } else {
      if (get().voiceOverPreviewAudio) get().voiceOverPreviewAudio.pause();

      const _audio = new Audio(_url);
      _audio.play();
      _audio.onended = () => {
        let _voice = { ...get().voiceOverPreview };
        _voice.action = 'pause';
        set({ voiceOverPreview: _voice });
      };

      _voiceOverPreview.action = 'play';

      set({ voiceOverPreviewAudio: _audio });
    }

    set({ voiceOverPreview: _voiceOverPreview });
  }
});
