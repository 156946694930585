export const getPrompt = (topic, language) => {
  return {
    label: 'A Day in the Life',
    topic: `A Day in the Life of "${topic}"`,
    description: `"A day in the life" short-videos offer a unique and captivating way to showcase what it's like to live a certain lifestyle or experience a specific event. They can be used to give viewers a behind-the-scenes look at a specific profession, such as a day in the life of a doctor or a chef, or to document a day in the life of a person experiencing a unique event, such as a backpacker traveling to a remote location. These videos provide an authentic and relatable experience, giving the audience a chance to see the world from a different perspective and learn something new. Whether you're looking to promote a business, brand, or product, or simply want to document and share your own personal experiences, "A day in the life" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@casey',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936034/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJXcj97zkWMNabnC3OhGfYWdEwUDyWLDDWoU0IGdkw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'CaseyNeistat',
        channel: '@casey',
        subscribers: '12.5M',
        description: `A filmmaker and vlogger who shares daily vlogs that document his daily activities, creative projects, and travels.`
      },
      {
        url: 'https://www.youtube.com/@MrBeast',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936053/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJV-BviaWnNvLLWoK18JTaIs6IC6adCul_JzqgQtkA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'MrBeast',
        channel: '@MrBeast',
        subscribers: '130M',
        description: `A popular content creator who shares daily vlogs and videos that showcase his philanthropy, stunts, and challenges.`
      },
      {
        url: 'https://www.youtube.com/@MrKate',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936069/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJU1XkBMxNRI-YRCCKhqYIz-eWftTtJfKvVzBox8rQo_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Mr. Kate',
        channel: '@MrKate',
        subscribers: '3.92M',
        description: ` A lifestyle and DIY creator who shares daily vlogs, home renovation projects, and fashion tutorials.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User Prompt: ${topic}

    Develop immersive and fascinating short video scripts depicting 'A Day in the Life' of any given subject, perfectly tailored to viewer prompts. The narrative should be so enthralling that viewers of all demographics remain absorbed. Utilize a five-scene strategy to flesh out a vivid and engaging portrait of the subject's day-to-day experience.
    
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};
