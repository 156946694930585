import { gtmFireEvent as trackingAction } from '~/helpers/gtm';
import * as Sentry from '@sentry/nextjs';

export const sendPrompt = (prompt, systemRolPrompt) =>
  new Promise((resolve, reject) => {
    const apiMessage = [
      { role: 'system', content: systemRolPrompt().promptContent },
      {
        role: 'user',
        content: prompt
      }
    ];

    const apiFunctions = [
      {
        name: 'process_video_data',
        description: 'Process and extract information from the provided video data',
        parameters: {
          type: 'object',
          properties: {
            videos: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  musicCategory: {
                    type: 'string',
                    description: 'The category of the music, e.g. Romantic'
                  },
                  scenes: {
                    type: 'array',
                    description:
                      'Array of scenes that contain information about the music personality',
                    items: {
                      type: 'object',
                      properties: {
                        voiceover: {
                          type: 'string',
                          description: 'Voiceover text for the scene'
                        }
                      }
                    }
                  },
                  meta: {
                    type: 'object',
                    description: 'Metadata for the video',
                    properties: {
                      title: {
                        type: 'string',
                        description: 'Title of the video'
                      },
                      description: {
                        type: 'string',
                        description: 'Description of the video'
                      },
                      hashtags: {
                        type: 'string',
                        description: 'Social media hashtags for the video'
                      },
                      mediaDescription: {
                        type: 'string',
                        description: 'Description of the media'
                      }
                    },
                    required: ['title', 'description', 'hashtags', 'mediaDescription']
                  }
                },
                required: ['musicCategory', 'scenes', 'meta']
              }
            }
          },
          required: ['videos']
        }
      }
    ];

    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-api-openai'
    });

    let API_HOST = 'https://woxo.tech';

    if (typeof window !== 'undefined') {
      API_HOST = window.location.origin;
    }

    fetch(`${API_HOST}/api/i2v/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        messages: apiMessage,
        functions: apiFunctions
      })
    })
      .then((response) => response.json())
      .then((result) => {
        let responseMessage = result.choices[0].message;
        if (responseMessage.function_call) {
          const data = JSON.parse(responseMessage.function_call.arguments);
          resolve(data.videos);
        } else {
          console.log('ERROR - api.openai - message - ', responseMessage);
          trackingAction({
            event: 'onButtonClick',
            category: 'idea-to-video',
            action: 'error-api-openai',
            params: {
              error: `${responseMessage}`
            }
          });
          Sentry.captureException(responseMessage);
          reject(responseMessage);
        }
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        Sentry.captureException(err);
        reject(err);
      });
  });

export const sendMultiplePrompts = (messages) =>
  new Promise((resolve, reject) => {
    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-openai-api'
    });

    let API_HOST = 'https://woxo.tech';

    if (typeof window !== 'undefined') {
      API_HOST = window.location.origin;
    }

    fetch(`${API_HOST}/api/i2v/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        messages: messages
      })
    })
      .then((response) => response.json())
      .then((result) => {
        let responseMessage = result.choices[0].message.content;
        resolve(responseMessage);
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        Sentry.captureException(err);
        reject(err);
      });
  });
