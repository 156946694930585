export const getPrompt = () => {
  return {
    promptContent: `
    Suggest new videos considering the prompt of my previous videos (that are related to previous creations and are not identical, use your creativity and enrich the content).
    Take into account the language of the prompts so that the suggestions are in that language.

    Return an array with 8 suggestions with the following structure:
    [
    {
    "prompt": "Video prompt"
    }
    ]`
  };
};
