export const getPrompt = (topic, language) => {
  return {
    label: 'A Horror Story',
    topic: `A Horror Story about "${topic}"`,
    description: `Horror stories short-videos are a great way to entertain and thrill audiences with spine-tingling tales of terror. They can take the form of traditional ghost stories, urban legends, or even modern horror fiction. They can be used to create a sense of suspense and fear, while also providing a fun and exciting way to be entertained. They can be used to showcase a wide range of horror genres, such as psychological horror, supernatural horror, and more. Whether you're a writer looking to share your own horror stories, or a brand looking to create engaging and thrilling content, horror stories short-videos are a great choice. Just keep in mind that this type of content is not suitable for all audiences, so it's important to consider the target audience and the platform where the video will be shared.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676942189/Woxo/Idea2video/category_details/a_horror_story/a_horror_story_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676942189/Woxo/Idea2video/category_details/a_horror_story/a_horror_story_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676942189/Woxo/Idea2video/category_details/a_horror_story/a_horror_story_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@DrNoSleep',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676940956/Woxo/Idea2video/category_details/a_horror_story/6ayd6CLUua-9hceEn2ium7BCrPlSKBeXx8nLHI4yUemaNuzIVI2Ib8iHFIA443vAJ7la8oi22dA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Dr. NoSleep',
        channel: '@DrNoSleep',
        subscribers: '481K',
        description: `A channel that shares short horror stories submitted by viewers and read by voice actors.`
      },
      {
        url: 'https://www.youtube.com/@chillingtalesfordarknights',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676940917/Woxo/Idea2video/category_details/a_horror_story/AL5GRJWlzFqT23FqCdFSL2r8DFll6-VNUltOjK0FtdC-_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Chilling Tales for Dark Nights',
        channel: '@chillingtalesfordarknights',
        subscribers: '413K',
        description: ` A channel that features short horror stories, ranging from classic tales to original works.`
      },
      {
        url: 'https://www.youtube.com/@hauntedsunshinegirl',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676940857/Woxo/Idea2video/category_details/a_horror_story/AL5GRJWMhrF_mM-X3fjnolKjF05soesd9VLi4mOjZJkEsw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'The Haunting of Sunshine Girl Network',
        channel: '@hauntedsunshinegirl',
        subscribers: '605K',
        description: `A channel that features videos documenting the real-life experiences of a young woman and her ghostly encounters.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User Prompt: ${topic}

    Construct terrifying and absorbing short video scripts illustrating 'A Night in the Life' of any given ${topic}, meticulously matched to viewer prompts. The narratives should be so chilling that viewers across all demographics simply can't look away. Implement a five-scene technique to bring to life a haunting and suspenseful depiction of the topic's nightmarish sequence.
    
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};
