export const getPrompt = (topic, language) => {
  return {
    label: 'Tips',
    topic: `Tips about "${topic}"`,
    description: `"Tips" short-videos are a great way to provide helpful and practical advice on a variety of topics. They can be used to offer solutions to common problems, share insider knowledge, or simply provide a fresh perspective on a subject. They offer a hands-on approach to learning, allowing viewers to implement the tips and see real results. They can be used to teach a wide range of skills, from basic to advanced, making them suitable for a wide range of audiences, from beginners to experts. Whether you're looking to teach a specific skill or just want to share your own personal tips and tricks, "Tips" short-videos are a great choice. They are perfect for social media, websites, and other digital channels, as they can be easily shared and viewed on a variety of devices.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676954619/Woxo/Idea2video/category_details/tips/tips_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676954619/Woxo/Idea2video/category_details/tips/tips_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676954619/Woxo/Idea2video/category_details/tips/tips_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@Tati',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676952819/Woxo/Idea2video/category_details/tips/AL5GRJXc4eMu9ExRsU3b2QbBaOQmqF9joucJJnl9vOiraQ_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Tati',
        channel: '@Tati',
        subscribers: '8.41M',
        description: `A channel that provides tips on makeup, skincare, and fashion.`
      },
      {
        url: 'https://www.youtube.com/@buzzfeedtasty',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676952839/Woxo/Idea2video/category_details/tips/sx2UTbpCoLz6v3SwIs_dKSRclgfP3V4KcPNEGjXgijfF18lx-UG8xW_9kOoi-Fzntcw_OGtjng_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Tasty',
        channel: '@buzzfeedtasty',
        subscribers: '21M',
        description: `A channel that provides cooking tips and tutorials for easy and delicious meals.`
      },
      {
        url: 'https://www.youtube.com/@Lifehacker',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676952871/Woxo/Idea2video/category_details/tips/AL5GRJUcWjrFyzQToaTC1bC5ddFLUy6Kekb4m-9_fMOW-g_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Lifehacker',
        channel: '@Lifehacker',
        subscribers: '471K',
        description: `A channel that provides answers to frequently asked questions about productivity, personal finance, and other life skills.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User Prompt: ${topic}

    Develop informative and engaging short video scripts that center around a specific 'Tip', the given ${topic}, expertly tailored to viewer prompts. The narrative should be so practical and compelling that it keeps viewers from all demographics thoroughly engaged. Use a five-scene approach to detail the relevance, application, and benefits of the tip.
    
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};
