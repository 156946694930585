export const getPrompt = (topic, language) => {
  return {
    label: 'What if',
    topic: `What if "${topic}"`,
    description: `"What if" short-videos offer a thought-provoking way to explore different scenarios and possibilities. They can be used to imagine what might have happened if a certain event or decision had gone differently, or to explore hypothetical situations. They can be used to entertain, educate and inform the audience by providing a different perspective on a topic. They can be used to show the potential consequences of a specific event or decision and how it can change the course of history. They can also be used to show the potential impact of a new technology, law or policy. Whether you're a history buff looking to share your knowledge or a brand looking to create engaging and informative content, "What if" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676959528/Woxo/Idea2video/category_details/what_if/what_if_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676959528/Woxo/Idea2video/category_details/what_if/what_if_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676959528/Woxo/Idea2video/category_details/what_if/what_if_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@AumSumWhatIf',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676956751/Woxo/Idea2video/category_details/what_if/h6mlebluOEPzYA7vT6rMCSDcXXQ5nMXG5-76vZUeGVggED7nnL9f2flTgVFHOUmXZZZeRHLXUo0_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AumSum - What If',
        channel: '@AumSumWhatIf',
        subscribers: '296k',
        description: `A channel that explores hypothetical scenarios and their potential consequences, focused on children's stories.`
      },
      {
        url: 'https://www.youtube.com/@AsapSCIENCE',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676956776/Woxo/Idea2video/category_details/what_if/AL5GRJU1smsnzPU533Gu1vekyh-uHcLt75Dh67ZuswiRew_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AsapSCIENCE',
        channel: '@AsapSCIENCE',
        subscribers: '10.3M',
        description: `A channel that explores a wide range of scientific topics, including what would happen if certain elements or phenomena disappeared.`
      },
      {
        url: 'https://www.youtube.com/@AlternateHistoryHub',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676956806/Woxo/Idea2video/category_details/what_if/AL5GRJUlgEPBHEf_sVjTrG8EIq1FuifizuZLmJByaZH7nw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AlternateHistoryHub',
        channel: '@AlternateHistoryHub',
        subscribers: '2.18M',
        description: `A channel that explores different scenarios and possibilities in history if certain events had gone differently.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User Prompt: ${topic}

    Develop imaginative and engrossing short video scripts that create a 'What If' story centered on a given ${topic}, expertly tailored to viewer prompts. The narrative should be so thought-provoking that viewers across all demographics remain captivated. Implement a five-scene approach to envision and explore the potential outcomes of the scenario.
    
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};
