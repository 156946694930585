export const getPrompt = () => {
  // README: This prompt assumes that the voiceover property comes with all the separators, so before
  // passed to the video and the voiceover API the characters should be removed
  return {
    promptContent: `
    Create one or many short video scripts depending on the user prompt that viewers can't resist watching. You must return the videos in a valid JSON format with a structure that will be used by our app called WOXO to create videos based on your instructions. 

    Each scene contains a ‘voiceover’ and an ‘image-prompt’ properties.
    
    The ‘voiceover’ is divided into phrases or keywords demarcated by the ‘~’ symbol. Significant phrases or keywords must be emphasized with asterisks, such as “word.” Example: “voiceover”: “Did you *know* ~ that ~ the first *programming language* ~ was ~ created in *1883?*” Ensure each voiceover is under 20 words.
    
    The 'image-prompt' describes a detailed image for each scene.
    
    A JSON array of video scripts may include one or multiple scripts, as shown below: [
          {
            “musicCategory”: “[Select a suitable Music Category from: Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark, Dramatic, Sad, Hip Hop]“,
            “scenes”: [
              {
                “voiceover”: “*Keyword* ~ Phrase ~ *Phrase* ~ Keyword”, “image-prompt”: “Create a prompt to generate an image for this scene”
              },
              {
                “voiceover”: “*Keyword* ~ Phrase ~ *Phrase* ~ Keyword”, “image-prompt”: “create a prompt to generate an image for this scene”
              },
              {
                “voiceover”: “*Keyword* ~ Phrase ~ *Phrase* ~ Keyword”, “image-prompt”: “create a prompt to generate an image for this scene”
              },
              {
                “voiceover”: “*Keyword* ~ Phrase ~ *Phrase* ~ Keyword”, “image-prompt”: “create a prompt to generate an image for this scene”
              }
            ],
            “meta”: {
              “title”: "Craft a compelling, SEO-friendly video title. It should hint at the content's surprising nature, sparking curiosity and intrigue. Remember to encapsulate the video's essence in a few words while creating suspense, similar to 'The Fastest Animal Isn't What You Think'. Your title should be so tantalizing that viewers can't resist clicking.",
              “description”: “A brief, YouTube-friendly video description”,
              “hashtags”: “Video-related hashtags to boost up your views, separated by spaces: #hashtag1 #hashtag2”,
              “mediaDescription”: “Create a narrative describing an image that depicts the main subject of the video (If the main subject is a person or a portrait). This image should fit the video’s theme. (Always in English)”
            }
          }
        ]`
  };
};
